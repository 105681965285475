import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Signin from "./pages/Auth/Signin/Signin";
import Signup from "./pages/Auth/Signup/Signup";
import Videos from "./pages/Dashboard/Videos";
import * as routeConstant from "./common/routeConstants";
import Dashboard from "./pages/Dashboard/Dashboard";
import UserDashboard from "./pages/Users/UserDashboard";
import ChangePassword from "./pages/Auth/ChangePassword/ChangePassword";
import { useSelector } from "react-redux";
import { RootState } from "./pages/Store/store";
import AuthVerify from "./common/AuthVerify";
import { TailSpin } from "react-loader-spinner";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword/ResetPassword";
import AsignedBrandAndDealers from "./pages/Users/AsignedBrandAndDealers";
import CreateAndEditUser from "./pages/Users/CreateAndEditUser";
import ShowUsersDetails from "./pages/Users/ShowUsersDetails";

const App = () => {
  const { user, admin } = useSelector((state: RootState) => state.auth);
  let token = user?.token || admin?.token;
  const loaderState: any = useSelector((state: RootState) => state.common.showLoader);

  return (
    <div className="App">
      <ToastContainer />
      {loaderState && <div className="loader-overlay">
        <div className="loader-container">
          <TailSpin color="#00BFFF" height={80} width={80} />
        </div>
      </div>}
      <Router>
        <Routes>
          <Route path="*" caseSensitive={false} element={<Navigate to={routeConstant.SIGNIN} />} />
          <Route path={routeConstant.SIGNIN} caseSensitive={false} element={<Signin />} />
          <Route path={routeConstant.SIGNUP} caseSensitive={false} element={<Signup />} />
          <Route path={routeConstant.FORGOT_PASSWORD} caseSensitive={false} element={<ForgotPassword />} />
          <Route path={routeConstant.RESET_PASSWORD} caseSensitive={false} element={<ResetPassword />} />
          {/* <Route path={routeConstant.CHANGE_PASSWORD} element={<ChangePassword />} /> */}

          {token &&
            <>
              <Route path="/" element={<Navigate to={routeConstant.DASHBOARD} />} />
              <Route path={routeConstant.VIDEOS} element={<Videos />} />
              {/* <Route path={routeConstant.SELECT_BRAND_DEALERS} element={<SelectBrandAndDealers />} /> */}
              <Route path={routeConstant.SELECT_BRAND_DEALERS} element={<AsignedBrandAndDealers />} />
              <Route path={routeConstant.DASHBOARD} element={<Dashboard />} />
              <Route path={routeConstant.ADMIN_DASHBOARD} element={<UserDashboard />} />
              <Route path={routeConstant.CHANGE_PASSWORD} element={<ChangePassword />} />
              <Route path={routeConstant.USER_CREATE} element={<CreateAndEditUser />} />
              <Route path={routeConstant.USER_EDIT} element={<CreateAndEditUser />} />
              <Route path={routeConstant.SHOW_USER_DETAILS} element={<ShowUsersDetails />} />
            </>
          }
        </Routes>
        <AuthVerify />
      </Router>
    </div>
  );
};

export default App;
